html {
  height: 100%;
}

body {
  height: 100%;
}

.form-title {
  font-size: 1.4rem !important;
  margin-bottom: 15px !important;
}

.phone-no {
  width: 100% !important;
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important;
}

.hr-line {
  align-items: center;
  -webkit-box-align: center;
  color: #aeaeae;
  display: flex;
  margin-top: 10px;
}

.hr-line span {
  padding: 0 5px;
}

.hr-line-1 {
  height: .5px;
  width: 100%;
  background: #dbdbdb;
  -webkit-box-flex: 1;
  flex: 1;
  display: block;
}

.google-btn {
  width: 100% !important;
  border-radius: 4px !important;
  height: 42px !important;
}

.google-btn > div {
  width: 38px !important;
  height: 38px !important;
  margin-top: 2px !important;
  margin-left: 2px !important;
}

.google-btn svg {
  width: 38px !important;
  height: 38px !important;
}

.google-btn span {
  line-height: 42px;
  display: block;
}

.additional-sign-text {
  width: 100%;
  text-align: center;
  color: #aeaeae;
}

.sign-box-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-left: 0;
  padding: 12px 20px;
}

.swith-language-select {
  font-size: 14px !important;
  color: #aeaeae !important;
}

.swith-language-select.MuiInput-underline:before {
  border: 0 !important;
}

.loading-backdrop {
  z-index: 1001 !important;
  color: #f4762a;
}

.login-container {
  height: 100%;
}

.login-header {
  /* background-color: #f4762a; */
  display: flex;
    flex-direction: column;
    align-items: center;
}

.login-header-logo {
  height: 90px;
  width: 76px;
  background-size: cover;
  background-image: url('assets/images/single-logo.png');
}

.MuiPhoneNumber-flagButton {
  width: min-content;
}